export const FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST' = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS' = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE' = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST' = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS' = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE' = 'RESET_PASSWORD_FAILURE';

export const REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST' = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS' = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE' = 'REGISTER_USER_FAILURE';

export const LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST' = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS' = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE' = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER_REQUEST: 'LOGOUT_USER_REQUEST' = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS' = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE: 'LOGOUT_USER_FAILURE' = 'LOGOUT_USER_FAILURE';

export const AUTH_USER_REQUEST: 'AUTH_USER_REQUEST' = 'AUTH_USER_REQUEST';
export const AUTH_USER_SUCCESS: 'AUTH_USER_SUCCESS' = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAILURE: 'AUTH_USER_FAILURE' = 'AUTH_USER_FAILURE';

export const UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST' = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS' = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE' = 'UPDATE_USER_FAILURE';

export const USER_CREATE_ORDER_REQUEST: 'USER_CREATE_ORDER_REQUEST' = 'USER_CREATE_ORDER_REQUEST';
export const USER_CREATE_ORDER_SUCCESS: 'USER_CREATE_ORDER_SUCCESS' = 'USER_CREATE_ORDER_SUCCESS';
export const USER_CREATE_ORDER_FAILURE: 'USER_CREATE_ORDER_FAILURE' = 'USER_CREATE_ORDER_FAILURE';
