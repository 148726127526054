export function jsonToQuery(json: any) {
    const map = [];
    for (const key in json) {
        if (!json.hasOwnProperty(key)) continue;
        map.push(`${key}=${json[key]}`);
    }
    return map.join("&");
}

export function setCookie(name: string, value: string | null, props?: any) {
    props = props || {};
    if (!props.path) {
        props.path = "/";
    }
    let exp = props.expires;
    if (typeof exp == 'number' && exp) {
        const d = new Date();
        d.setTime(d.getTime() + exp * 1000);
        exp = props.expires = d;
    }
    if (exp && exp.toUTCString) {
        props.expires = exp.toUTCString();
    }
    value = encodeURIComponent(value || "");
    let updatedCookie = name + '=' + value;
    for (const propName in props) {
        updatedCookie += '; ' + propName;
        const propValue = props[propName];
        if (propValue !== true) {
            updatedCookie += '=' + propValue;
        }
    }
    document.cookie = updatedCookie;
}

export function getCookie(name: string): string {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : "";
}
